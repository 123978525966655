import React, { useState } from "react";
import "./HomeConnect.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeConnectCard from "./HomeConnectCard/HomeConnectCard";
import Form from "../../General/Form/Form";

const HomeConnect = ({ data }) => {
    const [showModal, setShowModal] = useState(false)
  const settingse = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="homeConnect_container">
      <div className="homeConnect_block">
        <h1>Как подключить домашний интернет Nets</h1>
        <div className="homeConnect_item">
          <Slider {...settingse}>
            {data?.map((e) => (
              <HomeConnectCard
                id={e.id}
                title={e.title}
                subTitle={e.subTitle}
                active={e.active}
                setActive={setShowModal}
                key={e.id}
              />
            ))}
          </Slider>
        </div>
      </div>
      {
        showModal &&
      <div className="form_container">
        <div className="form_block">
          <Form show={showModal} setShowModal={setShowModal} id={677}/>
        </div>
      </div>
      }
    </div>
  );
};

export default HomeConnect;
